<template>
  <div class="manage_layer_02">
    <div class="layer_top">
      <h2 class="layer_top_title">{{ groupTitle }} 그룹</h2>
      <span class="btn_close" @click="$emit('close')">
        <img
          src="@/assets/img/close.png"
          style="width: 15px; height: 15px"
          alt="닫기"
        />
      </span>
    </div>
    <div class="manage_layer_box_02" style="max-height: 80vh">
      <form>
        <table>
          <colgroup>
            <col style="width: 128px" />
            <col style="width: 850px" />
          </colgroup>
          <tr class="line_style">
            <td class="box_style_02 list_title_style">그룹 명</td>
            <td class="box_style_02">
              {{ name }}
            </td>
          </tr>

          <tr class="line_style">
            <td class="box_style_02 list_title_style">스탬프</td>
            <td class="box_style_02" colspan="7">
              <base-table
                v-if="stamp.ids != ''"
                :useNo="true"
                :totalItems="totalItems"
                :currentPage="currentPage"
                :pageSize="pageSize"
                :loading="false"
                :fields="fields"
                :items="stamp.items"
                :isEdit="false"
                :isDelete="false"
                :isChecked="true"
                :defaultChecked="checkedStampIds"
                checkText="필수 스탬프"
              />
            </td>
          </tr>
          <tr class="line_style">
            <td class="box_style_02 list_title_style">이미지</td>
            <td class="box_style_02">
              <img
                v-if="picture.path"
                :src="`${picture.url}${picture.path}`"
                alt="메인"
                style="width: auto; max-width: 120px; height: 100%"
              />
            </td>
          </tr>
        </table>

        <div class="btn_center_wrapper">
          <base-button type="primary-lg" @click="$emit('close')"
            >확인</base-button
          >
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import client from "api-client";
import BaseButton from "./core/BaseButton.vue";
import Dialog from "@/components/Dialog.vue";
export default {
  components: { BaseButton },
  props: {
    id: {
      type: Number,
      default: () => 0,
    },
    status: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      groupTitle: "",
      name: "",
      picture: {
        url: "",
        path: "",
      },
      stamp: {
        items: [],
        ids: [],
        rewardItems: [],
        rewardStampId: 0,
      },
      fields: [
        {
          key: "picture",
          width: "120px",
          label: "스탬프",
          tag: "img",
        },
        {
          key: "name",
          width: "200px",
          label: "스탬프 명",
        },
      ],
      totalItems: 1,
      totalPages: 1,
      currentPage: 1,
      pageSize: 1,
      checkedStampIds: [],
      checkedRewardStampIds: [],
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    async fetch() {
      if (this.status == 8 || this.status == 9) {
        await client.oldGroupFindone(this.id).then(
          (response) => {
            const { data } = response;
            console.log(data);
            this.name = data.name;
            this.picture = data.content.picture;
          },
          (error) => {
            console.log(error);
          }
        );

        let params = {
          page: 1,
          size: 1000,
        };

        await client.oldStampGroupFindone(this.id, params).then(
          (response) => {
            const { data } = response;

            data.items.forEach((item) => {
              if (item.picture.name) {
                item.picture = `${item.picture.url}${item.picture.path}?size=100`;
              }
            });

            this.stamp.items = data.items;
            this.totalItems = this.stamp.items.length;
            this.stamp.items.forEach((item) => {
              this.stamp.ids.push(item.id);
            });
          },
          (error) => {
            console.log(error);
          }
        );
      } else {
        await client.groupFindone(this.id).then(
          (response) => {
            const { data } = response;
            console.log(data);
            this.name = data.name;
            this.picture = data.content.picture;
          },
          (error) => {
            const { data } = error.response;
            let message = data.message;
            this.$modal.show(
              Dialog,
              {
                title: "알림",
                content: message,
              },
              {
                adaptive: true,
                width: "90%",
                maxWidth: 600,
                height: "auto",
              },
              {
                "before-open": () => {},
                "before-close": () => {},
              }
            );
          }
        );
        let params = {
          page: 1,
          size: 1000,
        };

        await client.stampGroupFindone(this.id, params).then(
          (response) => {
            const { data } = response;
            data.items.forEach((item) => {
              if (item.picture.name) {
                item.picture = `${item.picture.url}${item.picture.path}?size=100`;
              }
            });

            this.stamp.items = data.items;
            this.totalItems = this.stamp.items.length;
            this.stamp.items.forEach((item) => {
              this.stamp.ids.push(item.id);
            });
          },
          (error) => {
            console.log(error);
          }
        );
      }

      if (this.status == 1) {
        this.groupTitle = "카테고리";
      } else if (this.status == 2) {
        this.groupTitle = "행정구역";
      } else if (this.status == 8) {
        this.groupTitle = "(구)카테고리";
      } else {
        this.groupTitle = "(구)행정구역";
      }
    },
  },
};
</script>
