<template>
  <div>
    <section class="cont_01">
      <form @submit="onSearch">
        <div class="searchform_cont_01">
          <!-- <span>
            <label>상태</label>
            <base-select
              :options="states"
              v-model="selectedState"
              placeholder="선택"
            />
          </span>
          -->
          <span>
            <label>등록일</label>
            <date-picker
              v-model="search.startDate.value"
              :popover="{ visibility: 'click' }"
              :max-date="new Date()"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  type="text"
                  :value="inputValue"
                  v-on="inputEvents"
                  class="dt_base"
                  readonly
                />
              </template>
            </date-picker>
            <span class="ml_04 mr_04">~</span>
            <date-picker
              v-model="search.endDate.value"
              :popover="{ visibility: 'click' }"
              :max-date="new Date()"
              :min-date="search.startDate.value"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  type="text"
                  :value="inputValue"
                  v-on="inputEvents"
                  class="dt_base"
                  readonly
                />
              </template>
            </date-picker>
          </span>
          <span>
            <label>검색어</label>
            <span>
              <base-select :options="searchTypeOptions" v-model="search.type" />
              <input
                type="text"
                v-model="search.q"
                placeholder="검색어를 입력해 주세요."
              />
              <base-button type="primary" nativeType="submit">검색</base-button>
            </span>
          </span>
        </div>
        <!-- <div class="searchform_cont_02">
          <span>
            <label>운영기간</label>
            <span>
              <span>
                <date-picker
                  v-model="periodStartDt"
                  :popover="{ visibility: 'click' }"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <input
                      type="text"
                      :value="inputValue"
                      v-on="inputEvents"
                      class="dt_base"
                      readonly
                    />
                  </template>
                </date-picker>
                <span class="ml_04 mr_04">~</span>
                <date-picker
                  v-model="periodEndDt"
                  :popover="{ visibility: 'click' }"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <input
                      type="text"
                      :value="inputValue"
                      v-on="inputEvents"
                      class="dt_base"
                      readonly
                    />
                  </template>
                </date-picker>
              </span>
              <span>
                <button
                  type="button"
                  class="date_search_btn"
                  @click="setDate(0)"
                >
                  오늘
                </button>
                <button
                  type="button"
                  class="date_search_btn"
                  @click="setDate(1)"
                >
                  어제
                </button>
                <button
                  type="button"
                  class="date_search_btn"
                  @click="setDate(2)"
                >
                  이번주
                </button>
                <button type="button" class="date_search_btn">
                  사용자 지정
                </button>
              </span>
            </span>
            <base-button type="primary" nativeType="submit">검색</base-button>
          </span>
        </div> -->
      </form>
    </section>
    <section class="cont_02">
      <div class="total_box addnumbox">
        <div>
          <div class="srch_total pl_40">
            <span class="srch_total_title">검색 :</span>
            <span class="total_count">{{ searchTotalItems }}</span>
            <span>명</span>
          </div>
          <div class="total ml_31">
            <span class="total_title">전체 :</span>
            <span class="total_count">{{ $n(totalItems) }}</span>
            <span>건</span>
          </div>
        </div>
        <span class="pr_40">
          <base-select
            :options="search.size.option"
            v-model="search.size.value"
            @input="fetch(1)"
            placeholder="선택"
          />
        </span>
      </div>
      <div class="table_area">
        <div>
          <base-table
            :useNo="true"
            :totalItems="totalItems"
            :currentPage="currentPage"
            :pageSize="pageSize"
            :loading="contentLoading"
            :fields="fields"
            :items="items"
            @click="onTableClick"
            @edit="onEditClick"
            @delete="onDeleteClick"
            @sort="onSortClick"
            :clickedNum="clickedNum"
          />
        </div>
        <base-pagination
          :total="totalItems"
          :loading="contentLoading"
          :currentPage="currentPage"
          :pageCount="totalPages"
          :perPage="pageSize"
          @change="fetch"
        />
      </div>
      <div class="btn_right_wrapper">
        <base-button
          class="mr_30"
          type="download"
          @click.prevent.stop="
            download('advertisement?download=xls', '카테고리 그룹 현황', 'xls')
          "
          :disabled="btnDisabled"
          >{{ downloadText }}</base-button
        >
        <base-button type="primary" @click="register">신규등록</base-button>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import client from "api-client";
import BaseTable from "@/components/core/BaseTable/BaseTable.vue";
import BaseButton from "@/components/core/BaseButton.vue";
import BasePagination from "@/components/core/BasePagination.vue";
import BaseSelect from "@/components/core/BaseSelect.vue";
import RegGroup from "@/components/RegGroup.vue";
import Dialog from "@/components/Dialog.vue";
import DetailGroup from "@/components/DetailGroup.vue";
import File from "@/common/file";
import { API_URL, SERVICE_API_URL } from "@/common/config";

export default {
  name: "MissionMissionGeneral",
  components: {
    BaseTable,
    BaseButton,
    BasePagination,
    BaseSelect,
  },
  data() {
    return {
      downloadText: "엑셀 다운로드",
      btnDisabled: false,
      currentSort: "",
      clickedNum: 0,
      searchTotalItems: 0,
      fields: [
        {
          key: "name",
          width: "200px",
          label: "그룹 명",
          useClick: true,
          useSort: true,
          clickedNum: 0,
        },
        {
          key: "stampCount",
          width: "100px",
          label: "스탬프 수",
          formatter: {
            name: "number",
          },
          useSort: true,
          clickedNum: 0,
        },
        {
          key: "user.name",
          width: "140px",
          label: "등록 담당자",
          useClick: true,
        },
        {
          key: "registerDate",
          width: "140px",
          label: "등록 일시",
          formatter: {
            name: "date",
            type: "short",
          },
          useSort: true,
          clickedNum: 0,
        },
      ],
      items: [],
      totalItems: 0,
      totalPages: 0,
      currentPage: 1,
      pageSize: 10,
      contentLoading: true,
      searchTypeOptions: [{ name: "그룹명", value: "name" }],
      search: {
        type: "",
        q: "",
        startDate: {
          value: "",
          masks: {
            input: "YYYY-MM-DD",
          },
        },
        endDate: {
          value: "",
          masks: {
            input: "YYYY-MM-DD",
          },
        },
        size: {
          event: true,
          option: [
            {
              name: "10개씩",
              value: 10,
            },
            {
              name: "20개씩",
              value: 20,
            },
            {
              name: "30개씩",
              value: 30,
            },
            {
              name: "40개씩",
              value: 40,
            },
            {
              name: "50개씩",
              value: 50,
            },
          ],
          value: 10,
        },
      },
      states: [
        { name: "전체", value: "전체" },
        { name: "운영 중", value: "운영 중" },
        { name: "정지", value: "정지" },
      ],
      selectedState: "",
      periodStartDt: this.$date().subtract(1, "month").format("YYYY-MM-DD"),
      periodEndDt: this.$date().format("YYYY-MM-DD"),
    };
  },
  created() {
    this.$Progress.start();
    this.fetch();
  },
  mounted() {
    this.$Progress.finish();
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
  },
  destroyed() {
    /**
     * destroyed
     */
  },
  methods: {
    download(url, name, type, params) {
      this.downloadText = "다운로드 중";
      this.btnDisabled = true;
      params = {
        page: this.currentPage,
        size: this.pageSize,
        download: "xls",
      };
      if (this.search.q && this.search.type) {
        params[this.search.type] = this.search.q;
      }
      url = `${API_URL}${SERVICE_API_URL}/group/category`;
      File.download(url, name, type, params, (response) => {
        console.log(response);
        // if (response.status == 200) {
        this.btnDisabled = false;
        this.downloadText = "엑셀 다운로드";
        // }
      });
    },
    onSearch(event) {
      event.preventDefault();
      this.fetch(1);
    },
    onEditClick(params) {
      this.$modal.show(
        RegGroup,
        {
          id: params.item.id,
          status: 1,
        },
        {
          clickToClose: false,
          width: 1314,
          height: "auto",
        },
        {
          "before-open": () => {},
          "before-close": (response) => {
            if (response.params) {
              if (response.params.refresh) {
                // 페이지를 유지하면서 새로고침
                this.fetch();
              }
            }
          },
        }
      );
    },
    onDeleteClick(params) {
      this.$modal.show(
        Dialog,
        {
          type: "confirm",
          title: "알림",
          content: "정말 삭제하시겠습니까?",
        },
        {
          width: 400,
          height: "auto",
        },
        {
          "before-open": () => {},
          "before-close": (response) => {
            if (response.params.confirm) {
              client.groupRemove(params.item.id).then(
                () => {
                  // 1 page 로 이동
                  this.fetch(1);

                  this.$modal.show(
                    Dialog,
                    {
                      title: "알림",
                      content: "정상적으로 삭제되었습니다.",
                    },
                    {
                      width: 400,
                      height: "auto",
                    },
                    {
                      "before-open": () => {},
                      "before-close": () => {},
                    }
                  );
                },
                (error) => {
                  console.log(error);
                }
              );
            }
          },
        }
      );
    },
    register() {
      this.$modal.show(
        RegGroup,
        {
          status: 1,
        },
        {
          clickToClose: false,
          width: 1314,
          height: "auto",
        },
        {
          "before-open": () => {},
          "before-close": (response) => {
            if (response.params) {
              if (response.params.refresh) {
                // 페이지를 유지하면서 새로고침
                this.fetch();
              }
            }
          },
        }
      );
    },
    setDate(val) {
      let curr = new Date();
      let first = curr.getDate() - curr.getDay();
      let last = first + 6;

      let firstday = new Date(curr.setDate(first)).toUTCString();
      let lastday = new Date(curr.setDate(last)).toUTCString();

      switch (val) {
        case 0:
          (this.periodStartDt = this.$date().format("YYYY-MM-DD")),
            (this.periodEndDt = this.$date().format("YYYY-MM-DD"));
          break;
        case 1:
          (this.periodStartDt = this.$date()
            .subtract(1, "day")
            .format("YYYY-MM-DD")),
            (this.periodEndDt = this.$date()
              .subtract(1, "day")
              .format("YYYY-MM-DD"));
          break;
        case 2:
          (this.periodStartDt = this.$date(firstday).format("YYYY-MM-DD")),
            (this.periodEndDt = this.$date(lastday).format("YYYY-MM-DD"));
          break;
      }
    },
    onTableClick(params) {
      this.$modal.show(
        DetailGroup,
        {
          status: 1,
          id: params.item.id,
        },
        {
          width: 978,
          height: "auto",
        },
        {
          "before-open": () => {},
          "before-close": () => {},
        }
      );
    },

    onSortClick(value) {
      if (value.item.key != this.currentSort) {
        this.clickedNum = 0;
        for (let i = 0; i < this.fields.length; i++) {
          this.fields[i].clickedNum = 0;
        }
      }
      this.currentSort = value.item.key;
      if (this.currentSort.includes(".")) {
        const sortString = this.currentSort.split(".");
        this.currentSort =
          sortString[0] +
          sortString[1][0].toUpperCase() +
          sortString[1].slice(1);
      }
      this.clickedNum = this.clickedNum < 2 ? this.clickedNum + 1 : 0;
      this.fields[value.index].clickedNum = this.clickedNum;
      if (!this.contentLoading) {
        this.fetch(1);
      }
    },
    async fetch(page = 1) {
      this.currentPage = page;
      this.contentLoading = true;
      let params = {
        page: this.currentPage,
        size: this.pageSize,
      };

      if (this.currentSort) {
        if (this.clickedNum == 1) {
          params.sort = `${this.currentSort}.asc`;
        } else if (this.clickedNum == 2) {
          params.sort = `${this.currentSort}.desc`;
        }
      }

      if (this.search.startDate.value && this.search.endDate.value) {
        params.startDate = this.$date(
          new Date(this.search.startDate.value)
        ).format("YYYY-MM-DD");
        params.endDate = this.$date(new Date(this.search.endDate.value)).format(
          "YYYY-MM-DD"
        );
      }

      if (this.search.q && this.search.type) {
        params[this.search.type] = this.search.q;
      } else if (0 < this.search.q.length) {
        params.important = this.search.q;
        /*
        this.$modal.show(
          Dialog,
          {
            title: "알림",
            content: "검색어 항목을 선택해 주세요.",
          },
          {
            width: 400,
            height: "auto",
          },
          {
            "before-open": () => {},
            "before-close": () => {},
          }
        );
        return;
        */
      }
      Object.entries(this.search).forEach((row) => {
        if (row[1].event) {
          if (row[1].value.length !== 0) {
            params[row[0]] = row[1].value;
          }
        }
      });

      await client.categoryGroupFindAll(params).then(
        (response) => {
          const { data } = response;
          data.items.forEach((item) => {
            if (item.status == 1) {
              item.status = "카테고리";
            } else {
              item.status = "행정구역";
            }
          });
          if (
            0 < this.search.q.length ||
            (this.search.startDate.value && this.search.endDate.value)
          ) {
            this.searchTotalItems = data.totalItems;
          }
          this.items = data.items;
          if (this.totalItems == 0) {
            this.totalItems = data.totalItems;
          }
          this.totalPages = data.totalPages;
          this.contentLoading = false;
        },
        () => {
          // console.log(message);
          this.items = [];
          // this.totalItems = 0;
          this.totalPages = 0;
          this.searchTotalItems = 0;
          this.contentLoading = false;
        }
      );
    },
  },
};
</script>

<style scoped lang="scss">
@import "./index.scss";
</style>
